.get-credit-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.get-credit-form {
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

.get-credit-title {
  text-align: center;
  margin-bottom: 20px;
}

.get-credit-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.get-credit-service-label {
  text-align: center;
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}


.get-credit-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.get-credit-input.short-input1 {
  width: calc(90%);
}
.get-credit-input.short-input2 {
  width: calc(80%);
}

.get-credit-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px; /* הוספת רווח בין הכפתורים */
}

.get-credit-button:last-child {
  margin-bottom: 0; /* הסרת הרווח מהכפתור האחרון */
}

.get-credit-button:hover {
  background-color: #0056b3;
}

.get-credit-error {
  color: red;
  text-align: center;
}

.card-details-row {
  display: flex;
  justify-content: space-between;
}

.card-details-row > div {
  flex: 1;
  margin-right: 10px;
}

.card-details-row > div:last-child {
  margin-right: 0;
}


.custom-card .rccs__number {
  direction: ltr;
}