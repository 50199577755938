/* הגדרת הכיוון והפונטים */
.resume-container {
    direction: rtl;
    font-family: 'Arial', sans-serif;
    margin: 20px auto;
    width: 80%;
    max-width: 800px;
    background-color: #f9f9f9; /* רקע עדין */
    box-shadow: 0 0 10px rgba(0,0,0,0.1); /* צל קל */
    padding: 20px;
    border-radius: 8px; /* קצוות מעוגלים */
  }
  
  .resume-section {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee; /* מסגרת תחתונה עדינה */
  }
  
  .resume-section:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  /* עיצוב הכותרות והטקסט */
  h2 {
    color: #007bff; /* צבע כחול בהיר לכותרות */
    margin-bottom: 15px;
  }
  
  p {
    line-height: 1.6;
    color: #333; /* צבע טקסט כהה יותר לקריאות טובה */
    margin: 0; /* אפס הפרדות נוספות */
  }
  
  /* הדגשת קטגוריות חשובות */
  .professional-summary, .skills {
    background-color: #eef7ff; /* רקע בהיר יותר להדגשת סעיפים */
    padding: 15px;
    border-radius: 5px; /* קצוות מעוגלים למיכלים */
  }
  
  /* הדגשת טקסטים מסוימים */
  .professional-summary p, .education p {
    font-weight: bold;
  }
  
  /* תיקון פרטים קטנים והתאמות */
  .resume-container .rtl {
    text-align: right;
  }

  
  /* עיצוב כפתור ההורדה */
.download-button {
    direction: rtl;
    font-size: 16px;
    padding: 10px 20px;
    margin: 20px auto;
    background-color: #007bff; /* צבע רקע כחול */
    color: white; /* צבע טקסט לבן */
    border: none; /* הסרת המסגרת */
    border-radius: 5px; /* קצוות מעוגלים */
    cursor: pointer; /* מצביע כפתור עכבר */
    transition: background-color 0.3s; /* אנימציה עדינה לשינוי צבע */
  }
  
  .download-button:hover {
    background-color: #45a049; /* ירוק כהה יותר */
  }
  
  .download-button:focus {
    outline: none; /* הסרת הדגשה בפוקוס */
  }
  .download-button-container {
    margin: 20px auto;
    width: 80%;
    max-width: 800px;
    text-align: center;
    display: flex; /* הפוך את הקונטיינר לפלקסבוקס */
    justify-content: space-around; /* הפצת הכפתורים באופן אחיד בקונטיינר */
    align-items: center; /* מיישר את הכפתורים למרכז באופן אנכי */
    padding: 10px; /* הוספת פאדינג לקונטיינר */
  }
    

  .created-by {
    font-size: 12px; /* גודל פונט קטן */
    color: #666; /* צבע טקסט כהה */
    text-align: center; /* יישור למרכז */
    margin-top: 20px; /* מרווח מלמעלה */
    margin-bottom: 20px; /* מרווח מלמטה */
  }

  .email-send-button {direction: rtl;
    font-size: 16px;
    padding: 10px 20px;
    margin: 20px auto;
    background-color: #007bff; /* צבע רקע כחול */
    color: white; /* צבע טקסט לבן */
    border: none; /* הסרת המסגרת */
    border-radius: 5px; /* קצוות מעוגלים */
    cursor: pointer; /* מצביע כפתור עכבר */
    transition: background-color 0.3s; /* אנימציה עדינה לשינוי צבע */
  }
  
  .email-send-button:hover {
    background-color: #45a049; /* ירוק כהה יותר */
  }
  