body {
  font-family: 'Arial', sans-serif;
  direction: rtl; /* כיוון מימין לשמאל */
}

.container {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  max-width: 960px;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center; /* מרכוז האלמנטים פנימית */
  justify-content: center; /* מרכוז נוסף ואנכי */
}

.pricing-table {
  width: 100%; /* מרכוז הטבלה לפי רוחב */
  border-collapse: collapse;
  margin: 0; /* הסרת רווחים נוספים */
  text-align: center; /* מרכוז תוכן התאים */
}

.pricing-table th, .pricing-table td {
  padding: 8px;
  border: none;
}

.header-title {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}

.footer-note {
  font-style: italic;
  margin-top: 20px;
}


.container a {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #007BFF;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.container a:hover {
  background-color: #0056b3;
}
