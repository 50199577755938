body {
  font-family: Arial, sans-serif;
  font-size: 16px; /* גודל כתב כללי גדול יותר */
}

.form-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: right; /* ישור לימין */
}

.form-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.form-group input[type="text"], .form-group input[type="email"], .form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.9);
  margin-top: 5px;
  direction: ltr; /* קלט מיושר משמאל לימין */
  text-align: left; /* טקסט מיושר לשמאל */
}

.form-group input[type="text"], .form-group input[type="email"] {
  height: 40px; /* גובה קבוע לשדות טקסט ואימייל */
}

.form-group textarea {
  height: auto; /* גובה משתנה לפי התוכן */
}

.form-group button {
  display: block;
  width: 100%;
  padding: 10px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.form-group button:hover {
  background: #0056b3;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #007bff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-top: 15px;
  margin-right: auto;
  margin-left: auto;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* סגנונות לטבלה */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px; /* מרווח מעל הטבלה */
  direction: rtl; /* כיוון יישור מימין לשמאל */
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
  text-align: right; /* יישור כותרות מימין */
}

td {
  text-align: right; /* יישור תוכן הטבלה מימין */
  background: rgba(255, 255, 255, 0.8);
}

/* וודא שהטבלה לא תגלוש מחוץ למסגרת */
.form-container {
  overflow-x: auto;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.contact-us-button {
  background-color: rgba(0, 0, 0, 0.5); /* רקע זמני שקוף למחצה כדי לראות את הכפתור */
  cursor: pointer; /* משנה את צורת העכבר ללחצן */
  padding: 0; /* מסיר כל ריפוד פנימי */
  width: 50px; /* התאמת רוחב הכפתור לגודל האיקון */
  border-radius: 50%; /* הופך את התמונה לעגולה */
  height: 50px; /* התאמת גובה הכפתור לגודל האיקון */
  float: right;
  transition: transform 0.1s ease, opacity 0.1s ease;
  animation: blink 1s infinite;
}

.contact-us-button img {
  width: 100%; /* הגדרת האיקון למלא את כל שטח הכפתור */
  height: 100%; /* הגדרת האיקון למלא את כל שטח הכפתור */
  border-radius: 50%; /* הופך את התמונה לעגולה */
  object-fit: cover; /* ממלא את התמונה בתוך המסגרת העגולה */
}

.contact-us-button:active {
  transform: scale(0.95); /* מקטין את הכפתור מעט כדי להדגיש את הלחיצה */
  opacity: 0.8; /* משנה את השקיפות להדגשת הלחיצה */
}

.modal-overlay {
  position: fixed; /* מבטיח שהמודל יוצג כחלון קופץ מעל כל התוכן */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* רקע כהה למחצה מאחורי המודל */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* מבטיח שהמודל יופיע מעל כל הרכיבים האחרים */
}

.modal-content {
  background: #e6f7ff; /* רקע תכלת עדין */
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 90%;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* נותן עדיפות למודל עצמו על פני ה-overlay */
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content button {
  margin-top: 20px;
  padding: 10px 20px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-content button:hover {
  background: #0056b3;
}

.modal-content .form-group {
  margin-bottom: 15px;
  text-align: left; /* יישור התוויות והשדות לשמאל */
}

.modal-content .form-group label {
  display: block;
  margin-bottom: 5px;
  text-align: right; /* יישור התוויות לימין */
}

.modal-content .form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}
.modal-content .agent-info {
  font-size: 18px; /* גודל טקסט גדול יותר */
  font-weight: bold; /* הדגשת הטקסט */
}


.modal-content .form-actions {
  display: flex;
  justify-content: flex-end; /* מיישר את הכפתורים לימין */
  gap: 10px; /* מרווח של 10 פיקסלים בין הכפתורים */
  margin-top: 20px; /* מרווח מעל הכפתורים */
}