.jobForm {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  background-color: rgba(172, 242, 247, 0.78); /* לבן עם שקיפות */
}

.formGroup {
  margin-bottom: 15px;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.formGroup input, .formGroup textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.buttonContainer {
  text-align: center;
}
button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px; /* שומר על הגדרות הפדינג */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 50%;
  display: inline-block; /* הופך את הכפתור ל-inline-block כדי שיהיה אפשר למרכז אותו */
  margin: 0 auto; /* לא יעבוד בתור inline-block, צריך טיפול נוסף למירכוז */
  text-align: center; /* ממרכז את הטקסט בתוך הכפתור */
}


button:hover {
  background-color: #0056b3;
}



h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
}